import { gql } from '@apollo/client';

export const USER_LEGACY_COUNT_QUERY = gql`
    query legacyUsersCount($where: LegacyUserWhereInput!) {
        legacyUsersCount(where: $where)
    }
`;

export const USER_LEGACY_QUERY = gql`
    query legacyUsers($where: LegacyUserWhereInput!, $orderBy: LegacyUserOrderByInput, $skip: Int, $take: Int) {
        legacyUsers(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
            username
            email
            userId
            projectName
            createdAt
            necomUserId
        }
    }
`;

export const USER_LEGACY_IMPORT_MUTATION = gql`
    mutation importLegacyUser($data: LegacyUserImportInput!) {
        importLegacyUser(data: $data) {
            username
            email
            userId
        }
    }
`;
