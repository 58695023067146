import { useSelector } from 'react-redux';
import { IRootState } from '../types';
import { Theme } from 'react-select';

export const useTheme = () => {
    const theme = useSelector((state: IRootState) => state.theme);

    const themeHelper = (t: Theme) => ({
        ...t,
        colors: {
            ...t.colors,
            primary: theme === 'dark' ? '#bfbfbf' : t.colors.primary,
            primary25: theme === 'dark' ? '#616263' : t.colors.primary25,
            dangerLight: theme === 'dark' ? '#636f83' : t.colors.dangerLight,
            neutral0: theme === 'dark' ? '#2a2b36' : t.colors.neutral0,
            neutral10: theme === 'dark' ? '#636f83' : t.colors.neutral10,
            neutral20: theme === 'dark' ? '#1a1a1a' : t.colors.neutral20,
            neutral80: theme === 'dark' ? '#bfbfbf' : t.colors.neutral80,
        },
    });

    return { themeHelper };
};
