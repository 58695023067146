import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Suspense fallback={loading}>
        <Provider store={store}>
            <App />
        </Provider>
    </Suspense>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
