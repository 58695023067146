import { useMutation } from '@apollo/client';
import { RESET_ACQUISITION_MUTATION } from '../graphql/acquisition';
import { RestartOneAcquisitionMutation, RestartOneAcquisitionMutationVariables } from '../generated/graphql';

export const useAcquise = () => {
    const [resetAcquisition] = useMutation<RestartOneAcquisitionMutation, RestartOneAcquisitionMutationVariables>(RESET_ACQUISITION_MUTATION);

    const restartAcquisition = (acquisitionId: string) => {
        return resetAcquisition({
            variables: {
                where: {
                    acquisitionId,
                },
            },
        });
    };

    return {
        restartAcquisition,
    };
};
