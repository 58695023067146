import { createStore } from 'redux';

const initialState: {
    sidebarShow: boolean;
    asideShow: boolean;
    darkMode: boolean;
    isSlowMode: boolean;
    modalViewPayload: { type: string; id: string } | null;
} = {
    sidebarShow: true,
    asideShow: false,
    darkMode: false,
    isSlowMode: false,
    modalViewPayload: null,
};

const changeState = (state = initialState, { type, ...rest }: { type: unknown }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest };
        default:
            return state;
    }
};

const store = createStore(changeState);
export default store;
