import { gql } from '@apollo/client';

export const PROFILE_MUTATION_CREATE = gql`
    mutation createOneModeratedProfile($data: ModeratedProfileCreateInput!) {
        createOneModeratedProfile(data: $data) {
            __typename
            ... on InputErrors {
                errors {
                    errors
                    fieldName
                }
            }
            ... on ModeratedProfile {
                moderatedProfileId
                profileId
            }
        }
    }
`;

export const PICTURE_MUTATION_DELETE = gql`
    mutation deletePicture($where: PictureWhereUniqueInput!) {
        deletePicture(where: $where)
    }
`;
