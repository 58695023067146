import { useSelector } from 'react-redux';
import { IRootState } from '../types';
import { useEffect, useState } from 'react';

export const useSlowMode = (defaultPolling: number) => {
    const [polling, setPolling] = useState<number>(defaultPolling);
    const isSlowMode = useSelector((state: IRootState) => state.isSlowMode);

    useEffect(() => {
        const pollingInterval = isSlowMode ? 0 : defaultPolling;
        setPolling(pollingInterval);

        // console.log(pollingInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSlowMode]);

    return { polling };
};
