import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
    fragment UserFragment on User {
        userId
        email
        mobileNumber
        lastLogin
        role
        username
        updatedAt
        createdAt
        createdIP
        sendTransactionalEmail
        sendMarketingEmail
        sendSMS
        emailValidated
        emailValidatedAt
        mobileNumberValidated
        mobileNumberValidatedAt
        emailBounce
        emailSuspendedUntil
        conditionAcceptedAt
        client
        clientVersion
        c4fId
        hasPayed
        verified
        domain
        registerDomain
        unModerated
        coins
        bonus
        latitude
        longitude
        projectName
        socials {
            providerName
            socialId
            providerId
            registerDomain
        }
    }
`;

export const PROFILE_FRGAMENT = gql`
    fragment ProfileFragment on Profile {
        profileId
        profileName
        gender
        searches
        country
        timezone
        postcode
        furtherInformation
        themeName
        adminText
        languageCode
        hidden
        hideLocation
        birthDate
        createdAt
        shownLocation
        flirtText
        active
        premiumStatus
        completed
    }
`;

export const MODERATED_PROFILE_FRAGMENT = gql`
    fragment ModeratedProfileFragment on ModeratedProfile {
        moderatedProfileId
        fixed
        teams {
            team {
                moderationTeamId
                name
            }
            projectName
        }
        moderators {
            moderationUser {
                name
                moderationUserId
            }
        }
        dynamic
        isFSK18
        support
        imageSource
        creator {
            name
        }
        noNAWR
    }
`;

export const PROFILE_INTERESTS_FRAGMENT = gql`
    fragment ProfileInterestsFragment on Profile {
        profileInterests {
            interest {
                interestId
                name
                translations {
                    translation
                    languageCode
                }
                categoryId
            }
        }
    }
`;

export const PROFILE_FLAGS_FRAGMENT = gql`
    fragment ProfileFlagsFragment on Profile {
        profileFlags {
            flag {
                flagId
                name
                translations {
                    translation
                    languageCode
                }
            }
            createdAt
            flagValue {
                translations {
                    translation
                    languageCode
                }
                flagValueId
            }
        }
    }
`;
