import { gql } from '@apollo/client';

export const TRANSACTIONS_QUERY = gql`
    query findManyTransaction($orderBy: TransactionOrderByInput, $where: TransactionWhereInput, $take: Int = 20, $skip: Int = 0) {
        findManyTransaction(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
            transactionId
            providerName
            createdAt
            updatedAt
            status
            cost
            currency
            coins
            reference
            recurring
            projectName
            offer
            extra
            ip
            userId
            user {
                userId
                profileId
                email
                profile {
                    profileId
                    profileName
                    images(where: { main: { equals: true } }) {
                        main
                        path
                    }
                    shownLocation
                    postcode
                    gender
                    country
                }
                createdAt
                role
                lastLogin
                online
            }
            countryBilling
            countryUser
            countryGeo
            invoiceId
            net
            vatRate
        }
    }
`;

export const TRANSACTIONS_COUNT_QUERY = gql`
    query findManyTransactionCount($where: TransactionWhereInput) {
        findManyTransactionCount(where: $where)
    }
`;

export const COINS_HISTORY_QUERY = gql`
    query findManyCoinsHistory($where: CoinsHistoryWhereInput, $take: Int = 20) {
        findManyCoinsHistory(where: $where, take: $take, orderBy: { createdAt: desc }) {
            action
            coinsChanged
            bonusChanged
            createdAt
        }
    }
`;

export const BILLING_STATS_QUERY = gql`
    query findManyTransactionAggregate($where: TransactionWhereInputWithoutUser) {
        findManyTransactionAggregate(where: $where, _sum: { coins: true, cost: true, bonus: true }) {
            _sum {
                coins
                cost
                bonus
            }
        }
    }
`;
