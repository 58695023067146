import { useLazyQuery } from '@apollo/client';
import { useRef } from 'react';
import { FindOneUserQuery, FindOneUserQueryVariables } from '../generated/graphql';
import { FIND_ONE_USER_QUERY } from '../graphql';
import { useSelector } from 'react-redux';
import { IRootState } from '../types';

export const useUser = () => {
    const user = useRef<FindOneUserQuery['findOneUser']>(null);
    const activeProject = useSelector((state: IRootState) => state.activeProject);
    const [findUser, { loading }] = useLazyQuery<FindOneUserQuery, FindOneUserQueryVariables>(FIND_ONE_USER_QUERY, {
        onCompleted: (data) => {
            console.log('useUser -> data', data);
            user.current = data.findOneUser;
        },
    });
    const getUser = (userId: string) => {
        findUser({
            variables: {
                where: {
                    userId_projectName: {
                        userId,
                        projectName: activeProject,
                    },
                },
            },
        });
    };

    return {
        getUser,
        user,
        loading,
    };
};
