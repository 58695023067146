import { gql } from '@apollo/client';

export const INTEREST_CATEGORIES_QUERY = gql`
    query InterestCategories($where: InterestCategoryWhereInput) {
        findManyInterestCategory(where: $where) {
            name
            interestCategoryId
            translations {
                translation
                languageCode
            }
            interests {
                interestId
                categoryId
                active
                name
                translations {
                    translation
                    languageCode
                }
            }
        }
    }
`;
