import { useLazyQuery } from '@apollo/client';
import { useRef } from 'react';
import { FindManyModerationUserQuery, FindManyModerationUserQueryVariables } from '../generated/graphql';
import { useSelector } from 'react-redux';
import { IRootState } from '../types';
import { MODERATIONUSERS_QUERY } from '../views/moderation/graphql';

export const useModerator = () => {
    const mods = useRef<FindManyModerationUserQuery['findManyModerationUser'] | null>(null);
    const activeProject = useSelector((state: IRootState) => state.activeProject);

    const [loadMods] = useLazyQuery<FindManyModerationUserQuery, FindManyModerationUserQueryVariables>(MODERATIONUSERS_QUERY, {
        onCompleted: (data) => {
            mods.current = data?.findManyModerationUser;
        },
    });
    const getMods = (name?: string, team?: string | null) => {
        loadMods({
            variables: {
                where: {
                    projects: activeProject
                        ? {
                              some: {
                                  projectName: {
                                      equals: activeProject,
                                  },
                              },
                          }
                        : undefined,
                    teams: team
                        ? {
                              some: {
                                  moderationTeamId: {
                                      equals: team,
                                  },
                              },
                          }
                        : undefined,
                },
            },
        });
    };

    return {
        getMods,
        mods,
    };
};
