import { useLazyQuery, useMutation } from '@apollo/client';
import { OFFER_QUERY, TRANSACTION_CREATE } from '../graphql';
import { CreateOneTransactionMutation, FindManyOfferQuery, FindManyOfferQueryVariables, TransactionStatus } from '../generated/graphql';
import { useRef } from 'react';

export const useTransaction = () => {
    const [create] = useMutation<CreateOneTransactionMutation>(TRANSACTION_CREATE);
    const offers = useRef<FindManyOfferQuery['findManyOffer']>([]);
    const [findManyOffer] = useLazyQuery<FindManyOfferQuery, FindManyOfferQueryVariables>(OFFER_QUERY, {
        onCompleted: (data) => {
            offers.current = data.findManyOffer;
        },
    });

    const getOffers = (provider: string) => {
        findManyOffer({
            variables: {
                where: {
                    providerName: { equals: provider },
                    active: { equals: true },
                },
            },
        });
    };

    const transactionCreate = async (offer: NonNullable<FindManyOfferQuery['findManyOffer']>[number], userId: string) => {
        const { data, errors } = await create({
            variables: {
                data: {
                    reference: offer.offerId,
                    offer: offer.name,
                    cost: offer.cost,
                    coins: offer.coins,
                    status: TransactionStatus.Complete,
                    project: {
                        name: offer.projectName,
                    },
                    user: {
                        userId_projectName: {
                            userId,
                            projectName: offer.projectName,
                        },
                    },
                },
            },
        });
        return {
            ...data?.createOneTransaction,
            errors,
        };
    };

    return {
        transactionCreate,
        getOffers,
        offers,
    };
};
