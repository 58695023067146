import { useMutation } from '@apollo/client';
import { createContext, MutableRefObject, ReactNode } from 'react';
import { CreateOneLogMutation, CreateOneLogMutationVariables } from '../generated/graphql';
import { useGlobalClick } from '../hooks/useGlobalClick';
import { LOG_MUTATION } from '../views/argus/graphql';

type Props = {
    children: ReactNode;
};

export type LogContextType = {
    log: (input: CreateOneLogMutationVariables) => unknown;
    counter: MutableRefObject<number>;
};

export const LogContext = createContext<LogContextType>({
    log: () => {
        console.log('LogContext not yet ready');
    },
    counter: { current: 0 } as MutableRefObject<number>,
});

export const LogProvider = ({ children }: Props) => {
    const { counter } = useGlobalClick();
    const [execute] = useMutation<CreateOneLogMutation, CreateOneLogMutationVariables>(LOG_MUTATION, {
        onCompleted: () => {
            counter.current = 0;
        },
    });

    const log = async (input: CreateOneLogMutationVariables) => {
        return execute({
            variables: {
                data: {
                    ...input.data,
                    clicks: counter.current,
                },
            },
        });
    };

    return <LogContext.Provider value={{ log, counter }}>{children}</LogContext.Provider>;
};
