import { gql } from '@apollo/client';

export const NOTIFICATION_LOG_QUERY = gql`
    query notificationLogs($where: NotificationLogWhereInput, $take: Int = 50, $skip: Int = 0, $orderBy: NotificationLogOrderByInput) {
        findManyNotificationLog(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
            notificationLogId
            payloadType
            status
            source
            channel
            createdAt
            updatedAt
            user {
                userId
                username
                email
                role
                profile {
                    profileId
                    profileName
                    birthDate
                    gender
                    profileId
                }
            }
        }
    }
`;

export const NOTIFICATION_LOG_QUERY_COUNT = gql`
    query notificationLogsCount($where: NotificationLogWhereInput) {
        findManyNotificationLogCount(where: $where)
    }
`;
