import { gql } from '@apollo/client';

export const ADMINSESSIONS_QUERY = gql`
    query findManyAdminSession24($where: AdminSession24WhereInput, $orderBy: AdminSession24OrderByInput, $whereSession: AdminSessionWhereInput) {
        findManyAdminSession24(where: $where, orderBy: $orderBy) {
            adminSession24Id
            adminName
            admin {
                adminUserId
            }
            date
            click
            image
            modComplain
            supportMessage
            supportTicket
            systemComplain
            transaction
            userComplain
            verifyUser
            adminSessions(where: $whereSession) {
                adminSessionId
                adminName
                start
                end
                click
                image
                modComplain
                projectName
                supportMessage
                supportTicket
                systemComplain
                transaction
                userComplain
                verifyUser
            }
        }
    }
`;

export const LOG_MUTATION = gql`
    mutation createOneLog($data: LogCreateInput!) {
        createOneLog(data: $data) {
            __typename
            ... on ResultError {
                errors {
                    errorMessage
                    errorCode
                }
            }
            ... on AdminLogResultSuccess {
                adminLogId
            }
        }
    }
`;

export const LOG_QUERY = gql`
    query findManyAdminLog($where: AdminLogWhereInput, $orderBy: AdminLogOrderByInput, $skip: Int, $take: Int) {
        findManyAdminLog(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
            adminLogId
            adminName
            logData
            createdAt
            type
        }
    }
`;
