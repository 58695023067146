import { gql } from '@apollo/client';

export const AVATAR_QUERY = gql`
    query Avatars($languageCode: String) {
        findManyAvatar {
            translation(languageCode: $languageCode) {
                translation
            }
            avatarId
            gender
            path
        }
    }
`;
