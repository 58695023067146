import React, { createContext, useCallback, useState, ReactNode } from 'react';
import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react-pro';

type Props = {
    children: ReactNode;
};

type Toast = {
    persist?: boolean;
    color?: string;
    title?: string;
    message: string;
    exception?: string;
    error?: string;
    link?: boolean;
};

export type ToastContextType = {
    addToast: (toast: Toast) => void;
};

export const ToastContext = createContext<ToastContextType | null>(null);

const ToastDefaultValues: Toast = { title: 'Notification', color: 'warning', persist: false, link: false, message: '' };

export const ToastProvider = ({ children }: Props) => {
    const [toasts, setToasts] = useState<Toast[]>([]);

    const addToast = useCallback(
        (toast: Toast) => {
            setToasts((toasts) => [...toasts, toast]);
        },
        [setToasts],
    );

    return (
        <ToastContext.Provider value={{ addToast }}>
            {children}
            <CToaster placement="top-end">
                {toasts.map((toast, index) => {
                    return (
                        <React.Fragment key={index}>
                            <CToast
                                visible={true}
                                autohide={!(toast.persist || ToastDefaultValues.persist)}
                                delay={3000}
                                animation={true}
                                color={toast.color ? toast.color : ToastDefaultValues.color}
                                className="text-white align-items-center"
                            >
                                <CToastHeader closeButton>
                                    <div className="fw-bold me-auto">{toast.title ? toast.title : ToastDefaultValues.title}</div>
                                </CToastHeader>
                                <CToastBody>
                                    {toast.message}
                                    {toast.exception && (
                                        <>
                                            <hr />
                                            {toast.exception}
                                        </>
                                    )}
                                    {toast.error && (
                                        <>
                                            <hr />
                                            {toast.error}
                                        </>
                                    )}
                                    {toast.link !== null && toast.link}
                                </CToastBody>
                            </CToast>
                        </React.Fragment>
                    );
                })}
            </CToaster>
        </ToastContext.Provider>
    );
};
