import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
    mutation login($username: String!, $password: String!) {
        login(data: { username: $username, password: $password }) {
            __typename
            ... on ResultError {
                errors {
                    errorMessage
                    errorCode
                }
            }
            ... on AdminLoginResultSuccess {
                token
                adminUserId
                privileges
                c4fAuth {
                    checksum
                    productid
                    projectName
                    userid
                }
                languageCode
                projects {
                    name
                }
                adminUserTeams {
                    adminUserTeamId
                }
                agency {
                    agencyId
                    name
                }
            }
        }
    }
`;

export const ROLES_QUERY = gql`
    query findManyAdminRole($where: AdminRoleWhereInput) {
        findManyAdminRole(where: $where) {
            adminRoleId
            name
            AdminRolePrivilege {
                privilege
                adminRoleName
                createdAt
            }
        }
    }
`;

export const ROLE_UPDATE_MUTATION = gql`
    mutation updateOneAdminRole($where: AdminRoleWhereUniqueInput!, $data: AdminRoleUpdateInput!) {
        updateOneAdminRole(where: $where, data: $data) {
            name
            AdminRolePrivilege {
                privilege
                adminRoleName
                createdAt
            }
        }
    }
`;

export const ROLE_CREATE_MUTATION = gql`
    mutation createOneAdminRole($data: AdminRoleCreateInput!) {
        createOneAdminRole(data: $data) {
            name
            AdminRolePrivilege {
                privilege
                adminRoleName
                createdAt
            }
        }
    }
`;
