import { gql } from '@apollo/client';

export const DEFAULTTEXT_QUERY = gql`
    query DefaultText {
        findManyProfileText {
            profileTextId
            translations {
                languageCode
                translation
            }
        }
    }
`;
