import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import {
    FeatureName,
    FindOneProfileFeaturesQuery,
    FindOneProfileFeaturesQueryVariables,
    FindOneProfileHazelzQuery,
    FindOneProfileHazelzQueryVariables,
    FindOneProfileQuery,
    FindOneProfileQueryVariables,
} from '../generated/graphql';
import { FIND_ONE_PROFILE_FEATURE_QUERY, FIND_ONE_PROFILE_QUERY, FIND_ONE_PROFILE_HAZELZ_QUERY } from '../graphql';
import { useSelector } from 'react-redux';
import { IRootState } from '../types';

const chooseQuery = (projectName: string) => {
    if (projectName === 'diggalo' || projectName === 'zoonda') {
        return FIND_ONE_PROFILE_FEATURE_QUERY;
    } else if (projectName === 'hazelz') {
        return FIND_ONE_PROFILE_HAZELZ_QUERY;
    } else {
        return FIND_ONE_PROFILE_QUERY;
    }
};

export const useProfile = () => {
    // ref is not good, please consider using signal
    const [profile, setProfile] = useState<FindOneProfileQuery['findOneProfile']>(null);
    const activeProject = useSelector((state: IRootState) => state.activeProject);
    const features = useSelector((state: IRootState) => state.features);
    const [findProfile, { loading }] = useLazyQuery<
        FindOneProfileQuery | FindOneProfileFeaturesQuery | FindOneProfileHazelzQuery,
        FindOneProfileQueryVariables | FindOneProfileFeaturesQueryVariables | FindOneProfileHazelzQueryVariables
    >(chooseQuery(activeProject), {
        onCompleted: (data) => {
            setProfile(data.findOneProfile);
        },
    });
    const getProfile = (profileId: string, withImages = false, withProfileFlags = false, withProfileInterests = false) => {
        findProfile({
            variables: {
                withImages,
                withProfileFlags,
                withProfileInterests,
                withAvatar: features?.find((f) => f.name === FeatureName.Avatar) ? true : false,
                where: {
                    profileId_projectName: {
                        profileId,
                        projectName: activeProject,
                    },
                },
            },
        });
    };

    return {
        getProfile,
        profile,
        loading,
    };
};
