import { gql } from '@apollo/client';

export const ADMINUSERTEXT_MUTATION = gql`
    mutation createOneAdminUserText($data: AdminUserTextCreateInput!) {
        createOneAdminUserText(data: $data) {
            adminUserTextId
        }
    }
`;

export const ADMINUSERTEXT_QUERY = gql`
    query findManyAdminUserText($where: AdminUserTextWhereInput) {
        findManyAdminUserText(where: $where, orderBy: { createdAt: desc }, take: 100) {
            adminUserTextId
            creator {
                name
            }
            text
            createdAt
        }
    }
`;

export const ADMINTEXT_MUTATION = gql`
    mutation createOneAdminText($data: AdminTextCreateInput!) {
        createOneAdminText(data: $data) {
            adminTextId
        }
    }
`;

export const ADMINTEXT_QUERY = gql`
    query findManyAdminText($where: AdminTextWhereInput) {
        findManyAdminText(where: $where, orderBy: { createdAt: desc }, take: 100) {
            adminTextId
            creator {
                name
            }
            text
            createdAt
            type
            entityType
            entityId
        }
    }
`;
